// BrowserCheck.js
import React, { useEffect, useState } from 'react';

const BrowserCheck = () => {
  const [isOldChrome, setIsOldChrome] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const chromeVersionMatch = userAgent.match(/Chrome\/([0-9]+)/);

    if (chromeVersionMatch) {
      const chromeVersion = parseInt(chromeVersionMatch[1], 10);
      if (chromeVersion < 129) {
        setIsOldChrome(true);
        // Optionally, you can show an alert as well
        alert(`Your Chrome version is ${chromeVersion}. Please update to a newer version for security.`);
      }
    }
  }, []);

  return null;
};

export default BrowserCheck;