import { useContext } from 'react'
import { AppState } from '../App'
import { TailSpin } from 'react-loader-spinner';

const Homepage = () => {
  const useAppState = useContext(AppState);

  const CorrectSvg = () => (
    <svg
      className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );

  return (
    <div>
      <header className="fixed w-full z-10">
        <nav className="bg-white border-gray-200 py-2.5 dark:bg-[#181a1e] transition-colors duration-1000 shadow-sm">
          <div className="flex flex-wrap items-center justify-between max-w-screen-xl px-4 mx-auto">
            <a href="/" className="flex items-center">
              <img src="logo512.png" className="h-16 mr-3" alt="Billionaire" />
              {/* <span className="self-center text-xl font-bold whitespace-nowrap dark:text-white"><span className='text-[#FFE900]'>Billionaire</span></span> */}
            </a>
            <div className="flex items-center lg:order-2">
              {
                useAppState.loading
                ? 
                  <div className="w-full flex justify-center">
                      <TailSpin height={32} />
                  </div>
                : 
                  <button onClick={() => useAppState.Login()}
                    className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 dark:bg-[#f7f7f8] dark:hover:bg-white focus:outline-none dark:focus:ring-gray-300 dark:text-gray-900"
                  >
                    {useAppState.isConnected ? 'Login/Join' : 'Connect'}
                  </button>
              }

              {
                useAppState.isConnected
                ?
                <button onClick={() => useAppState.disconnectwallet()}
                  className="text-white ms-5 bg-red-500 hover:bg-red-600 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 dark:bg-red-500 dark:hover:bg-red-600 focus:outline-none dark:focus:ring-red-400"
                >
                  Disconnect
                </button>
                : ''
              }
             
            </div>
          </div>
        </nav>
      </header>
      
      {
      !useAppState.termsConditions && useAppState.isConnected
      ?
      <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className=" bg-neutral-900 px-4 py-3 sm:px-6 text-center ">
                <h3 className="text-lg font-semibold text-white" id="modal-title">Terms and Conditions</h3>
              </div>
              <div className="bg-neutral-800 p-4 sm:p-5">
                <div className="sm:flex sm:items-start">
                  <div className="text-center sm:text-left">
                    <p className="text-sm text-white mb-4">I am aware that registration with the purchase of any package is useful for participation in the community and its growth and to allow the development of my business. I am also aware that purchases made on this platform do not constitute sources of investment but the potential earnings are linked to the dynamics of growth and development of the community.</p>
                    <p className="text-sm text-white">I am fully aware of the functioning of the compensation plan and its dynamics. With full awareness and under my exclusive responsibility I declare that I agree and accept all potential risks of any nature connected to this activity</p>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-3 bg-neutral-900 px-4 py-3 sm:px-6">
                <button onClick={() => useAppState.TermCondition()} type="button" className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Agree</button>
                <button onClick={() => useAppState.disconnectwallet()} type="button" className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500">Disconnect</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      : ''
      }

      <section className="bg-white dark:bg-[#14151A] transition-colors duration-1000">
        <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
          <div className="mr-auto place-self-center lg:col-span-7">
            <p className="max-w-2xl mb-4 text-gray-500 font-bold text-xl dark:text-purple-500">
              The fastest, secure and easy way to realize your dreams!
            </p>
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl text-[#FFE900]">
              The power to build<br />your future
            </h1>
            <p className="max-w-2xl mb-6 text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-[#c4c5cb]">
              Billionaire is a pioneering Web3 decentralized platform powered by blockchain and smart contracts,  designed to empower users on their journey towards economic independence and desired lifestyles.  
              Billionaire use the disruptive blockchain technology to foster a collaborative community where success is shared, amplified, and accessible to all with few very simple steps, driving growth through innovation and support.
            </p>
            <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
              <button
                onClick={() => useAppState.Login()}
                className="text-white bg-pink-500 hover:bg-pink-600 focus:ring-4 focus:ring-pink-300 font-medium rounded-lg text-sm px-6 lg:px-8 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 dark:bg-[#f7f7f8] dark:text-black dark:hover:bg-white focus:outline-none dark:focus:ring-gray-300"
              >
                {useAppState.isConnected ? 'Login/Join' : 'Start Now'}
              </button>
            </div>
          </div>
          <div className="mt-8 lg:mt-0 lg:col-span-5 lg:flex">
            <img src="hero.jpg" alt="hero image" />
          </div>
        </div>
      </section>

      <section className="bg-gray-50 dark:bg-[#1e2026]">
        <div className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
          <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16 flex flex-col md:flex-col-reverse">
            <div className="text-gray-500 sm:text-lg dark:text-gray-400">
              <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                FOR EVERYONE -
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                Billionaire platform is built following the best practices for an easy, smooth and intuitive user experience. You will use the most cutting-edge blockchain technology with few simple clicks.
              </p>

              <ul
                role="list"
                className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700"
              >
                <li className="flex space-x-3">
                  <CorrectSvg />
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    No Document Required
                  </span>
                </li>
                <li className="flex space-x-3">
                  <CorrectSvg />
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Fully and truly decentralised platform
                  </span>
                </li>
                <li className="flex space-x-3">
                  <CorrectSvg />
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Totally ownership free
                  </span>
                </li>
              </ul>
              <p className="mb-8 font-light lg:text-xl">
                Join us now and secure your financial future with our innovative
                platform.
              </p>
            </div>
            <img
              className="w-[400px] mb-4 rounded-lg lg:mb-0 lg:flex mx-auto"
              src="feature-1.png"
              alt="dashboard feature image"
            />
          </div>

          <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16  flex flex-col-reverse md:flex-col">
            <img
              className="w-[400px] mb-4 rounded-lg lg:mb-0 lg:flex mx-auto"
              src="feature-2.png"
              alt="feature image 2"
            />
            <div className="text-gray-500 sm:text-lg dark:text-gray-400">
              <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                Distinctive features -
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                Billionaire is a platform built to provide unparalleled business opportunities that help you to grow and secure your futures. Billionaire platform scope is to bring to you the most profitable financial opportunities in the new Decentralized Finance era.
              </p>

              <ul
                role="list"
                className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700"
              >
                <li className="flex space-x-3">
                  <CorrectSvg />
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Very negligible entry/ start up cost ( 0.18 BNB )
                  </span>
                </li>
                <li className="flex space-x-3">
                  <CorrectSvg />
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    100% instant disbursal of rewards & profits
                  </span>
                </li>
                <li className="flex space-x-3">
                  <CorrectSvg />
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Earn up to 237000 BNB and more
                  </span>
                </li>
                <li className="flex space-x-3">
                  <CorrectSvg />
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    No team building compulsory
                  </span>
                </li>
                <li className="flex space-x-3">
                  <CorrectSvg />
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Huge passive income/profits
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white dark:bg-[#14151A] transition-colors duration-1000">
        <div className="items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:py-24 lg:px-6">
          <div className="col-span-2 mb-8">
            <h2 className="mt-3 mb-4 text-3xl font-extrabold tracking-tight text-gray-900 md:text-3xl dark:text-white">
             Globally Trusted -
            </h2>
            <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">
              Billionaire is a smart contract built to dismantle barriers and foster a more inclusive financial landscape , everything happens in real time and no one can change or tamper with it.
            </p>
          </div>
          <div className="col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40px"
                height="40px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#f0b90b"
                  d="M6.544 5.061L12 2l5.455 3.061l-2 1.133L12 4.261L8.55 6.194zm10.911 3.867l-2-1.134L12 9.728L8.55 7.794L6.544 8.928v2.26l3.445 1.934v3.861l2.01 1.134l2.006-1.134v-3.86l3.45-1.934zm0 6.128v-2.267l-2 1.133v2.261zm1.428.8l-3.45 1.927v2.267l5.456-3.067v-6.122l-2.006 1.128zm-2.005-8.862l2 1.134v2.26l2.01-1.127V6.994l-2.005-1.133L16.878 7zm-6.89 11.612v2.26L12 22l2.005-1.133V18.61L12 19.74l-2.006-1.134zM6.545 15.05l2 1.133v-2.26l-2-1.134v2.267zM9.99 6.994L12 8.128l2.006-1.134L12 5.861L9.994 7zM5.116 8.128l2.006-1.134l-2-1.133L3.11 7v2.267l2.005 1.128zm0 3.861L3.11 10.861v6.122l5.455 3.067v-2.272L5.122 15.85v-3.867z"
                />
              </svg>
              <h3 className="mb-2 mt-3 text-2xl font-bold dark:text-white">
                BSC
              </h3>
              <p className="font-light text-gray-500 dark:text-gray-400">
                Empowering decentralized finance with efficiency and scalability
                on Binance Smart Chain.
              </p>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40px"
                height="40px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#f0b90b"
                  d="M6.544 5.061L12 2l5.455 3.061l-2 1.133L12 4.261L8.55 6.194zm10.911 3.867l-2-1.134L12 9.728L8.55 7.794L6.544 8.928v2.26l3.445 1.934v3.861l2.01 1.134l2.006-1.134v-3.86l3.45-1.934zm0 6.128v-2.267l-2 1.133v2.261zm1.428.8l-3.45 1.927v2.267l5.456-3.067v-6.122l-2.006 1.128zm-2.005-8.862l2 1.134v2.26l2.01-1.127V6.994l-2.005-1.133L16.878 7zm-6.89 11.612v2.26L12 22l2.005-1.133V18.61L12 19.74l-2.006-1.134zM6.545 15.05l2 1.133v-2.26l-2-1.134v2.267zM9.99 6.994L12 8.128l2.006-1.134L12 5.861L9.994 7zM5.116 8.128l2.006-1.134l-2-1.133L3.11 7v2.267l2.005 1.128zm0 3.861L3.11 10.861v6.122l5.455 3.067v-2.272L5.122 15.85v-3.867z"
                />
              </svg>
              <h3 className="mb-2 mt-3 text-2xl font-bold dark:text-white">
                BNB
              </h3>
              <p className="font-light text-gray-500 dark:text-gray-400">
                Efficient coin transactions facilitated seamlessly through
                BSC's infrastructure.
              </p>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40px"
                height="40px"
                viewBox="0 0 256 256"
              >
                <g fill="none">
                  <rect width="256" height="256" fill="#65afff" rx="60" />
                  <path
                    fill="#000"
                    d="m165.536 25l-37.53 58.79H53L90.503 25z"
                    opacity="0.45"
                  />
                  <path
                    fill="#000"
                    d="M128.006 83.79h75.033L165.536 25H90.503z"
                    opacity="0.6"
                  />
                  <path
                    fill="#000"
                    d="m90.503 142.557l37.503-58.767L90.503 25L53 83.79z"
                    opacity="0.8"
                  />
                  <path
                    fill="#000"
                    d="m90.867 230.742l37.529-58.79h75.033l-37.53 58.79z"
                    opacity="0.45"
                  />
                  <path
                    fill="#000"
                    d="M128.396 171.952H53.363l37.503 58.79H165.9z"
                    opacity="0.6"
                  />
                  <path
                    fill="#000"
                    d="m165.899 113.185l-37.503 58.767l37.503 58.79l37.529-58.79z"
                    opacity="0.8"
                  />
                </g>
              </svg>
              <h3 className="mb-2 mt-3 text-2xl font-bold dark:text-white">
                Smart Contract
              </h3>
              <p className="font-light text-gray-500 dark:text-gray-400">
                Automating agreements securely with transparent blockchain smart
                contracts.
              </p>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40px"
                height="40px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="none"
                  stroke="#9b1c1c"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5.636 5.636a9 9 0 1 0 12.728 12.728M5.636 5.636a9 9 0 1 1 12.728 12.728M5.636 5.636L12 12l6.364 6.364"
                />
              </svg>
              <h3 className="mb-2 mt-3 text-2xl font-bold dark:text-white">
                No Admin
              </h3>
              <p className="font-light text-gray-500 dark:text-gray-400">
                Decentralized governance ensures autonomy and transparency
                without administrators.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* ROADMAP */}
      <div className="items-center dark:bg-[#1e2026] p-4 md:p-8 gap-8 lg:grid lg:grid-cols-2 xl:gap-16 flex flex-col-reverse">
        <div className="text-gray-500 sm:text-lg dark:text-gray-400">
          <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
            The Ecosystem Roadmap -
          </h2>
          <p className="mb-8 font-light lg:text-xl">
            The specialists involved in Billionaire have a strong experience in DeFi with a clear objective to create real financial  opportunities in the most profitable markets worldwide accessible from everyone.
          </p>
          <p className="mb-8 font-light lg:text-xl">
            The Billionaire platform is the first step that unites the future owners of the entire ecosystem in a community dedicated to improving their own lifestyle by achieving simple and achievable  high value goals. A clear focus on building a bucket of added value services to provide a constant growing passive income to the community components.
           </p>
        </div>
        <img
              className="w-full mb-4 rounded-lg lg:mb-0 lg:flex"
              src="dex.webp"
              alt="dashboard feature image"
            />
      </div>

      <section className="bg-gray-50 dark:bg-[#1e2026]">
        <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-24 lg:px-6">
          <figure className="max-w-screen-md mx-auto">
            <svg
              className="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600"
              viewBox="0 0 24 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                fill="currentColor"
              />
            </svg>
            <blockquote>
              <p className="text-xl font-medium text-gray-900 md:text-2xl dark:text-white">
                "Success begins with a single step. Believe in your potential,
                seize opportunities, and never give up. Your dedication and
                perseverance will turn dreams into reality. Start your journey
                to greatness today!"
              </p>
            </blockquote>
          </figure>
        </div>
      </section>

      <footer className="bg-white dark:bg-[#14151A]">
        <div className="max-w-screen-xl p-4 py-6 mx-auto lg:py-16 md:p-8 lg:p-10">
          <div className="text-center">
            <a
              href="#"
              className="flex flex-col items-center justify-center mb-5 text-2xl font-semibold text-gray-900 dark:text-white"
            >
              <img
                src="logo.png"
                className=""
                width="110"
                alt="Billionaire Logo"
              />
              <span className="text-yellow-500">Billionaire</span>
            </a>
            <span className="block text-sm text-center text-gray-500 dark:text-gray-400">
              © 2025. All Rights Reserved. Built with passion ❤️ on{" "}
              <a
                target="_blank"
                href="https://bscscan.com/address/0x8aFfF2b4B14D3629A98F109DAC9162f6094F789F"
                className="text-yellow-500 hover:underline dark:text-yellow-500"
              >
                BSC blockchain
              </a>
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Homepage